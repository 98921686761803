import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { t } from 'bv-i18n';
import { Carousel, Skeleton, Icon } from 'bv-components';

import { makeGetComponentById } from '../selectors';
import { priceItUpsFetchData, priceItUpsReset } from '../duck';
import PriceItUp from './price_it_up';

const App = ({
  componentId,
  title,
  ...config
}) => {
  const dispatch = useDispatch();
  const getComponentById = makeGetComponentById();
  const component = useSelector(
    (state) => getComponentById(state, componentId),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(priceItUpsFetchData(componentId, config));

    return () => dispatch(priceItUpsReset(componentId));
  }, [componentId]);

  if (!component) return null;

  const { bets, loaded } = component;

  if (!loaded) return <Skeleton skeletonType="priceitup-wrapper" />;
  if (!bets?.length) return null;

  return (
    <div className="priceitup-wrapper">
      <h2 className="bvs-header bvs-ellipsis">
        <Icon className="bvs-event-icon is-piu" big />
        {title || t('javascript.price_it_up.title')}
      </h2>
      <Carousel scrollStep={284} largeArrows>
        {bets.map((bet) => (
          <PriceItUp key={bet.eventId} betBuilder2={config.betBuilder2} {...bet} />
        ))}
      </Carousel>
    </div>
  );
};

App.propTypes = {
  componentId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default App;
