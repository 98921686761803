import _ from 'underscore';

import { addOutcomes, removeOutcomes } from 'sportsbook-outcomes-duck';
import { makeGetComponentById } from './selectors';
import fetchData from './api';

const getUniqueSportIds = _.compose(_.unique, _.partial(_.map, _, _.property('sportId')));

export const PRICE_IT_UPS_FETCH_DATA_INIT = 'priceItUps/FETCH_DATA_INIT';
export const PRICE_IT_UPS_FETCH_DATA_SUCCESS = 'priceItUps/FETCH_DATA_SUCCESS';
export const PRICE_IT_UPS_RESET = 'priceItUps/RESET';

export const priceItUpsFetchDataInit = (componentId) => ({
  type: PRICE_IT_UPS_FETCH_DATA_INIT,
  componentId,
});

export const priceItUpsFetchDataSuccess = (componentId, priceItUps) => ({
  type: PRICE_IT_UPS_FETCH_DATA_SUCCESS,
  componentId,
  priceItUps,
});

export const priceItUpsResetSuccess = (componentId) => ({
  type: PRICE_IT_UPS_RESET,
  componentId,
});

export const priceItUpsReset = (componentId) => (dispatch, getState) => {
  const getComponentById = makeGetComponentById();
  const outcomeIds = getComponentById(getState(), componentId)
    ?.bets?.flatMap(_.property('outcomeIds'));

  dispatch(removeOutcomes(outcomeIds));
  dispatch(priceItUpsResetSuccess(componentId));
};

export const priceItUpsFetchData = (componentId, config) => (dispatch) => {
  dispatch(priceItUpsFetchDataInit(componentId));
  return fetchData(config)
    .then((data) => {
      dispatch(addOutcomes(data.outcomes));
      dispatch(priceItUpsFetchDataSuccess(componentId, data.priceItUps));
    });
};

const initialState = {
  componentsById: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case PRICE_IT_UPS_FETCH_DATA_INIT:
      return {
        componentsById: {
          ...state.componentsById,
          [action.componentId]: {
            sportIds: [],
            bets: [],
            loaded: false,
          },
        },
      };
    case PRICE_IT_UPS_FETCH_DATA_SUCCESS:
      return {
        componentsById: {
          ...state.componentsById,
          [action.componentId]: {
            sportIds: getUniqueSportIds(action.priceItUps),
            bets: action.priceItUps,
            loaded: true,
          },
        },
      };
    case PRICE_IT_UPS_RESET:
      return {
        componentsById: _.omit(state.componentsById, action.componentId),
      };
    default:
      return state;
  }
};
