import reducer from '../duck';
import App from './app';

const { addReducers } = window.reduxState;

addReducers({
  priceItUps: reducer,
});

export default App;
