import { getJSON } from 'bv-fetch';

const emptyResponse = () => [];

export default ({
  sportIds,
  filter,
  opponent,
  eventsPerSport,
  outcomesPerEvent,
  legsPerOutcome,
  eventIds,
  eventIdsToExclude,
  excludeGameEvents,
  excludeRankEvents,
}) => (
  getJSON('/bv_api/price_it_up_home_component', {
    sport_ids: sportIds,
    filter,
    opponent,
    max_events_per_sport: eventsPerSport,
    max_outcomes_per_event: outcomesPerEvent,
    max_legs_per_outcome: legsPerOutcome || 2,
    event_ids: eventIds.join(','),
    event_ids_to_exclude: eventIdsToExclude.join(','),
    exclude_game_events: excludeGameEvents,
    exclude_rank_events: excludeRankEvents,
  }).catch(emptyResponse)
);
